import Icon from "@components/Ui/Icon";
import { Vendor } from "@contexts/vendor-context";
import { getImgProxyUrl } from "@utils/image-proxy";
import { useTranslation } from "react-i18next";

type Props = {
  vendor: Vendor | undefined;
};

export default ({ vendor }: Props) => {
  const { t } = useTranslation();
  const { name, vendorId, logo } = vendor || {};

  if (!vendor) return null;

  return (
    <a
      href={`/${vendorId}/analytics`}
      className="w-full space-x-3 rounded-md border border-gray-700 bg-gray-800 p-3 transition-colors row hover:border-gray-400 active:translate-y-px active:border-gray-500 md:p-4"
    >
      <span className="relative aspect-square w-[34px] overflow-hidden rounded-sm bg-white row">
        {logo && (
          <img
            className="w-full p-0.5"
            src={getImgProxyUrl(logo, ["rs:fill:64:0"])}
            alt={t("vendor_logo", { vendor: name }) || ""}
          />
        )}
        {!logo && <Icon name="sesamy-simple" className="text-xl text-black" />}
      </span>
      <span className="flex flex-1 items-start space-y-1 text-left column-left">
        <span className="text-sm leading-none tracking-wide first-letter:uppercase">
          {name || vendorId}
        </span>
      </span>
      <span className="aspect-square w-[40px] rounded-md bg-gray-700 row">
        <Icon name="chevron-right" />
      </span>
    </a>
  );
};
