import React, { useState } from "react";
import { Vendor, useVendorContext } from "@contexts/vendor-context";
import VendorCard from "@components/VendorSelect/VendorCard";
import { useTranslation } from "react-i18next";
import TextInput from "@components/Ui/TextInput";
import { twMerge } from "tailwind-merge";

const filterVendors = (vendors: Vendor[], text: string) =>
  text
    ? vendors.filter(
        ({ vendorId, name }) =>
          vendorId.includes(text.toLocaleLowerCase()) ||
          (name || "").toLocaleLowerCase().includes(text.toLocaleLowerCase()),
      )
    : vendors;

export default () => {
  const { vendors = [], getVendorSettings } = useVendorContext();
  const { t } = useTranslation();
  const [textFilter, setTextFilter] = useState("");

  const vendorsWithSettings = vendors
    .map(getVendorSettings)
    .filter((vendor) => vendor) as Vendor[];
  const filteredVendors = filterVendors(vendorsWithSettings, textFilter);
  const showFilter = vendors.length > 10;

  return (
    <>
      {showFilter && (
        <div className="mb-2">
          <TextInput
            name="vendor-filter"
            autoFocus
            tabIndex={0}
            onChange={({ target: { value } }) => setTextFilter(value)}
            placeholder={t("type_to_filter") || ""}
          />
        </div>
      )}
      <div
        className={twMerge(
          "custom-scrollbar scrollbar-gray-800 -mx-14 h-[288px] space-y-2 md:h-[320px]",
          (filteredVendors.length > 4 || showFilter) &&
            "overflow-y-scroll pl-14 pr-[calc(theme(space.14)-15px)] column-up",
          filteredVendors.length <= 4 &&
            !showFilter &&
            "overflow-y-visible px-14 column",
        )}
      >
        {filteredVendors.map((vendor) => (
          <VendorCard vendor={vendor} key={vendor.vendorId} />
        ))}
        {filteredVendors.length === 0 && (
          <span className="text-gray-300">{t("no_vendor_with_filter")}</span>
        )}
      </div>
    </>
  );
};
